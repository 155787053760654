import { useState, useEffect, useRef } from 'react';
import Signup from './Signup';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Criteria from '../../assets/critera.png';
import Table from 'react-bootstrap/Table';

export default function Preload() {
  const [agree, setAgree] = useState('');
  const signupRef = useRef(null);

  useEffect(() => {
    if (agree === 'Yes') {
      signupRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [agree]);

  return (
    <div>
      <Container fluid>
        <Table responsive="md" style={{ borderColor: 'white' }}>
          <tbody style={{ borderColor: 'white' }}>
            <tr style={{ borderColor: 'white' }}>
              <td className="image-cell" style={{ borderColor: 'white' }}>
                <div className="image-wrapper">
                  <img
                    className="criteria-image"
                    src={Criteria}
                    alt="img here"
                  />
                </div>
              </td>
              <td className="card-cell" style={{ borderColor: 'white' }}>
              <div className="card-wrapper">
                <Card >
                  <Card.Body>
                    <Card.Title>Agree to the Terms and Conditions:</Card.Title>
                    
                  </Card.Body>
                  <select
                    required
                    onChange={(e) => setAgree(e.target.value)}
                    value={agree}
                  >
                    <option>No</option>
                    <option>Yes</option>
                  </select>
                </Card>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <div ref={signupRef}>{agree === 'Yes' && <Signup></Signup>}</div>
      </Container>
      <style jsx>{`
        .image-cell {
          vertical-align: middle;
        }

        .image-wrapper {
          max-width: 100%;
          text-align: center;
        }


        .criteria-image {
          max-width: 100%;
          height: auto;
          max-height: 80vh; /* Adjust the maximum height as needed */
          margin: 0 auto;
        }

        .card-cell {
          vertical-align: middle;
          display: flex;
          justify-content: center;
        }

        .card-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;
        }

        @media (max-width: 1500px) {
          .card-cell {
            margin-top: 15rem;
          }
        @media (max-width: 768px) {
          .card-cell {
            margin-top: 2rem;
          }
        
        }
      `}</style>
    </div>
  );
}

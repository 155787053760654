import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import {Link} from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import { projectAuth } from '../../firebase/config';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    setIsLoading(true);

    // Send password reset email using Firebase
    try {
      await projectAuth.sendPasswordResetEmail(email);
      setSuccessMessage('Password reset email sent. Please check your inbox.');
    } catch (error) {
      setError('Email not found. Please enter a valid email address.');
    }

    setIsLoading(false);
  };

  return (
    <div>
      <h2>Forgot Password</h2>
      <Form onSubmit={handleSubmit} className="auth-form">
        <Form.Label>Email:</Form.Label>
        <Form.Control
          required
          type="email" 
          onChange={(e) => setEmail(e.target.value)} 
          value={email} 
        />
        <br />
        {!isLoading ? (
          <Button className="primary" type="submit">Send Email</Button>
        ) : (
          <Button className="primary" type="submit" disabled>Loading...</Button>
        )}
        {error && <div className="error">{error}</div>}

        {successMessage && <div className="success" style={{paddingTop:"1rem"}}><p>{successMessage}</p> <Link to="/login">Back To Login</Link></div>}

      </Form>
    </div>
  );
}

import { useState } from 'react'
import { useFirestore } from '../../hooks/useFirestore'
import { useHistory} from 'react-router'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row'

import Button from 'react-bootstrap/Button'
import { useParams } from 'react-router'

export default function UpdateScholarship({document}){
    const {id}=useParams()
    console.log(id)
    const history = useHistory()
    const { updateDocument, response } = useFirestore('projects')
    

  
    // form field values
    const [name, setName] = useState(document.name)
    const [applicable, setApplicable] = useState(document.applicable)
    const [website,setWebsite]=useState(document.website)
    const [formError, setFormError] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setFormError(null)
    
        const project = {
          name,
          applicable,
          website
        }
    
        await updateDocument(id,project)
        if (!response.error) {
          history.push('/list')
        } 
    }

    return(
        <div className="create-form">

      {document && <h2 className="page-title">Update Project: {document.name}</h2>}

      <Form onSubmit={handleSubmit}>
      <Row>
      
      <Form.Group as={Col} className="mb-3">
        <Form.Label>Scholarship Name: </Form.Label>
        <Form.Control
          required 
          type="text" 
          onChange={(e) => setName(e.target.value)} 
          value={name}
        />
      </Form.Group>
      
      
      
      <Form.Group as={Col} className="mb-3">
        <Form.Label>Website: </Form.Label>
        <Form.Control
          type="text" 
          onChange={(e) => setWebsite(e.target.value)} 
          value={website}
        />
      </Form.Group>
      </Row> 
      <Row>


        <Form.Group as={Col} className="mb-3">

        <Form.Label>Applicable For: </Form.Label>
        <Form.Control
          required 
          type="text" 
          onChange={(e) => setApplicable(e.target.value)} 
          value={applicable}
        />

      </Form.Group>
      
      </Row>
        
       

        <Button type="submit" className="primary">Update Project</Button>

        {formError && <p className="error">{formError}</p>}
      </Form>
    </div>
    )

}
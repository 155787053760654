import './Navbar.css';
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Button } from 'react-bootstrap';
import Logo from "../assets/hopeandlight.jpg";

export default function Navbar_Home() {
  const { logout, isPending } = useLogout();
  const { user } = useAuthContext();

  return (
    <>
      <Navbar bg="#FFFFFF" data-bs-theme="light" expand="md">
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              src={Logo}
              width="70"
              height="70"
              className="d-inline-block align-middle"
            />{' '}
            Empower with Hope and Light India

          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbar-collapse" />
          <Navbar.Collapse id="navbar-collapse">
            <Nav className="ms-auto">
              {!user && (
                <>
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/preload">Register </Nav.Link>

                  <Nav.Link href="/list">External Scholarships</Nav.Link>
                  <Nav.Link href="/publiclist">Student List</Nav.Link>
                  <Nav.Link href="/login">Student Login</Nav.Link>

                </>
              )}
              {user && (
                <>
                  <Nav.Link href="/list">External Scholarships </Nav.Link>

                  <Nav.Link href="/publiclist">Student List</Nav.Link>
                  <Nav.Link href="/">My Account</Nav.Link>

                  {!isPending && <Button onClick={logout}>Logout</Button>}
                  {isPending && (
                    <Button disabled>Logging Out...</Button>
                  )}
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <hr />
    </>
  );
}

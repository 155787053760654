import { useState } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';

const filterList = ['All', 'Pending', 'Approved', 'Denied'];

export default function ProjectFilter({ changeFilter }) {
  const [currentFilter, setCurrentFilter] = useState('All');

  const handleClick = (newFilter) => {
    setCurrentFilter(newFilter);
    changeFilter(newFilter);
  };

  return (
    <div className="project-filter">
      <h6>Filter by:</h6>

      <ButtonGroup>
        {filterList.map((f) => (
          <Button
            key={f}
            onClick={() => handleClick(f)}
            variant={currentFilter === f ? 'primary' : 'secondary'}
          >
            {f}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
}

import { useState } from 'react'
import { useSignup } from '../../hooks/useSignup'

// styles
import './Signup.css'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import "./Signup.css"
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
export default function Signup() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState(null)
  const [confirm_password, setConfirmPassword] = useState(null)

  const [displayName, setName] = useState('')
  const [aadhar,setAadhar]=useState('')
  const [dateOfBirth,setDateofBirth]=useState('')
  const [correspondenceAddress,setCorrespondenceAddress]=useState('')
  const [permanentAddress,setPermanentAddress]=useState('')
  const [phone,setPhone]=useState('')
  const [whatsappNumber,setWhatsappNumber]=useState('')
  const [fatherName,setFatherName]=useState('')
  const [fatherOccupation,setFatherOccupation]=useState('')
  const [fatherIncome,setFatherIncome]=useState('')
  const [motherName,setMotherName]=useState('')
  const [motherOccupation,setMotherOccupation]=useState('')
  const [motherIncome,setMotherIncome]=useState('')
  const [siblingDetails,setSiblingDetails]=useState('')
  const [siblings,setSiblings]=useState('')
  const [guardian,setGuardian]=useState('')
  const [guardianOccupation,setGuardianOccupation]=useState('')
  const [guardianIncome,setGuardianIncome]=useState('')
  const [scholarshipUsage,setScholarshipUsage]=useState('')
  const [year,setYear]=useState('1')
  const [college,setCollege]=useState('')
  const [previous,setPrevious]=useState('')
  const [previousPercentage,setPreviousPercentage]=useState('')
  const [totalFees,setTotalFees]=useState('')
  const [feesPaid,setFeesPaid]=useState('')
  const [entranceRank,setEntranceRank]=useState('')
  const [letter,setLetter]=useState('Yes')
  const [explanation, setExplanation]=useState('')
  const [familyFinancialDetails,setFamilyFinancialDetails]=useState('')
  const [reference1,setReference1]=useState('')
  const [reference2,setReference2]=useState('')
  const [agreement,setAgreement]=useState('')
  const [EWScert,setEWScert]=useState(null)
  const [admissionCert,setAdmissionCert]=useState(null)
  const [finAidCert,setFinAidCert]=useState('empty')
  const [status,setStatus]=useState('Pending')
  const [amount,setAmounth]=useState('')
  const [dateOfApproval,setDateOfApproval]=useState('')
  const [mode,setMode]=useState('')


  const [pdf, setPDF] = useState(null)
  const [pdf2, setPDF2] = useState(null)

  const [markseetError,setMarksheetError]=useState(null)
  const [markseet2Error,setMarksheet2Error]=useState(null)

  const [ewsError,setEWSerror]=useState(null)
  const [finError,setFinError]=useState(null)
  const [admissionError,setAdmissionError]=useState(null)
  const [dbAgree,setDbAgree]=useState('Yes')
  const [genError,setGenError]=useState(null)
  const [otherScholarship,setOtherScholarship]=useState(null)

  const { signup, isPending, error } = useSignup()
  const [type,setType]=useState('user')
  console.log(dbAgree)

  
  const handleSubmit = (e) => {
    e.preventDefault()
    signup(email, password, displayName, EWScert,finAidCert,admissionCert,aadhar,agreement,dateOfBirth,reference1,reference2,familyFinancialDetails,explanation,letter,entranceRank,totalFees,feesPaid,previous,previousPercentage,year,college,scholarshipUsage,guardian,guardianIncome,guardianOccupation,motherIncome,motherOccupation,motherName,fatherName,fatherIncome,fatherOccupation,siblingDetails,siblings,phone,whatsappNumber,permanentAddress,correspondenceAddress,pdf, pdf2, type,status,dbAgree,amount,dateOfApproval,mode,otherScholarship)
   
  }

  const handleEWSFileChange = (e) => {
    setEWSerror(null)
    let selected = e.target.files[0]

    if (!selected) {
      setEWSerror('Please select a file')
      return
    }
    if (!(selected.type.includes('pdf')  || selected.type.includes("image"))) {
      setEWSerror('Selected file must be an image or pdf')
      return
    }
    if (selected.size > 3000000) {
      setEWSerror('File size must be less than 3MB')
      return
    }
    
    setEWScert(selected)
    console.log('EWS updated')
  }
  const handleMarksheetFileChange = (e) => {
    setMarksheetError(null)
    let selected = e.target.files[0]

    if (!selected) {
      setMarksheetError('Please select a file')
      return
    }
    if ( !(selected.type.includes('pdf') || selected.type.includes("image"))) {
      setMarksheetError('Selected file must be an image or pdf')
      return
    }
    if (selected.size > 3000000) {
      setMarksheetError('File size must be less than 1000kb')
      return
    }
    
    setPDF(selected)
    console.log('Marksheet updated')
  }

  const handleMarksheet2FileChange = (e) => {
    setMarksheet2Error(null)
    let selected = e.target.files[0]

    if (!selected) {
      setMarksheet2Error('Please select a file')
      return
    }
    if ( !(selected.type.includes('pdf') || selected.type.includes("image"))) {
      setMarksheet2Error('Selected file must be an image or pdf')
      return
    }
    if (selected.size > 3000000) {
      setMarksheet2Error('File size must be less than 1000kb')
      return
    }
    
    setPDF2(selected)
    console.log('Marksheet updated')
  }

 
  const handleFinAidFileChange = (e) => {
    setFinError(null)
    let selected = e.target.files[0]

    if (!selected) {
      setFinError('Please select a file')
      return
    }
    if (!(selected.type.includes('pdf') || selected.type.includes("image"))) {
      setFinError('Selected file must be an image or pdf')
      return
    }
    if (selected.size > 3000000) {
      setFinError('File size must be less than 1000kb')
      return
    }
    
    setFinAidCert(selected)
    console.log('Marksheet updated')
  }
  const handleAdmissionFileChange = (e) => {
    setAdmissionError(null)
    let selected = e.target.files[0]

    if (!selected) {
      setAdmissionError('Please select a file')
      return
    }
    if (!(selected.type.includes('pdf') || selected.type.includes("image"))) {
      setAdmissionError('Selected file must be an image or pdf')
      return
    }
    if (selected.size > 3000000) {
      setAdmissionError('File size must be less than 1000kb')
      return
    }
    
    setAdmissionCert(selected)
  }
  return (
<>
<br></br>
<h6>Instructions:</h6>
    <ul>
    <li>Please fill all the fields. Input NA if not-applicable
</li>
<li>Mail to hopeandlightindia@gmail.com in case of queries 
</li>
<li>Aadhar copy and bank statements for one year has to be provided during the face-to-face meeting
</li>
  </ul>
    <Form onSubmit={handleSubmit} className="auth-form">
      <br></br>
      
      <Row>
      <p style={{textAlign:"center", fontSize:"20px"}}><strong>Personal Details</strong></p>
      <hr></hr>


      <Form.Group as={Col} className="mb-3">
        <Form.Label>Full Name: </Form.Label>
        <Form.Control
          required 
          type="text" 
          onChange={(e) => setName(e.target.value)} 
          value={displayName}
        />
      </Form.Group>

        <Form.Group as={Col} className="mb-3">

        <Form.Label>Aadhar Card Number: </Form.Label>
        <Form.Control
          required 
          type="number" 
          onChange={(e) => setAadhar(e.target.value)} 
          value={aadhar}
        />

      </Form.Group>

      </Row>
      <Row>

      <Form.Group as={Col} className="mb-3" >
    
        <Form.Label>Date of Birth: </Form.Label>
        <Form.Control
          required 
          type="date" 
          onChange={(e) => setDateofBirth(e.target.value)} 
          value={dateOfBirth}
        />
      </Form.Group>

      <Form.Group as={Col} className="mb-3" >
        <Form.Label>Correspondence Address: </Form.Label>
        <Form.Control
          required 
          type="text" 
          onChange={(e) => setCorrespondenceAddress(e.target.value)} 
          value={correspondenceAddress}
        />
      </Form.Group>

      </Row>
      <Row>
      <Form.Group as={Col} className="mb-3" >

        
        <Form.Label>Permanent Address: </Form.Label>
        <Form.Control
          required 
          type="text" 
          onChange={(e) => setPermanentAddress(e.target.value)} 
          value={permanentAddress}
        />
        
      </Form.Group>
      <Form.Group as={Col} className="mb-3" >

       
        <Form.Label>Phone number: </Form.Label>
        <Form.Control
          required 
          type="text" 
          onChange={(e) => setPhone(e.target.value)} 
          value={phone}
        />
        
      </Form.Group>
      </Row>
      <Row>

       
      <Form.Group as={Col} className="mb-3" >
        
        <Form.Label>Alternate/whatsapp number (Optional):  </Form.Label>
        <Form.Control
          type="text" 
          onChange={(e) => setWhatsappNumber(e.target.value)} 
          value={whatsappNumber}
        />
        
      </Form.Group>

      <Form.Group as={Col} className="mb-3" >
        
        <Form.Label>Email:</Form.Label>
        <Form.Control
          required 
          type="email" 
          onChange={(e) => setEmail(e.target.value)} 
          value={email}
        />
        
      </Form.Group>
      </Row>
      <Row>
      <Form.Group as={Col} className="mb-3" >
        
        <Form.Label>Password:</Form.Label>
        <Form.Control
          required
          type="password" 
          onChange={(e) => setPassword(e.target.value)} 
          value={password}
        />
        
      </Form.Group>

     
      <Form.Group as={Col} className="mb-3" >
        
         
        <Form.Label>Confirm Password:</Form.Label>
        <Form.Control
          required
          type="password" 
          onChange={(e) => setConfirmPassword(e.target.value)} 
          value={confirm_password}
        />
        
      </Form.Group>
      

      </Row>
      {confirm_password!==null && password!==confirm_password && <p>Password and the Confirm Password fields do not match.</p>}
      <Row>


      <p style={{textAlign:"center", fontSize:"20px"}}><strong>Family Details</strong></p>
      <hr></hr>
    

      <Form.Group as={Col} className="mb-3" >
        
        <Form.Label>Father's name:</Form.Label>
        <Form.Control
          type="text" 
          onChange={(e) => setFatherName(e.target.value)} 
          value={fatherName}
        />
        
      </Form.Group>
      </Row>
      <Row>
      
      <Form.Group as={Col} className="mb-3" >

        
        <Form.Label>Father's occupation:</Form.Label>
        <Form.Control
          type="text" 
          onChange={(e) => setFatherOccupation(e.target.value)} 
          value={fatherOccupation}
        />
        
      </Form.Group>

      <Form.Group  as={Col} className="mb-3" >
        
        <Form.Label>Father's income:</Form.Label>
        <Form.Control
          type="text" 
          onChange={(e) => setFatherIncome(e.target.value)} 
          value={fatherIncome}
        />
        

      </Form.Group>
      </Row>
      <Row>

      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>Mother's Name:</Form.Label>
        <Form.Control
          type="text" 
          onChange={(e) => setMotherName(e.target.value)} 
          value={motherName}
        />
        
      </Form.Group>

      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>Mother's Occupation:</Form.Label>
        <Form.Control
          type="text" 
          onChange={(e) => setMotherOccupation(e.target.value)} 
          value={motherOccupation}
        />
      </Form.Group>
      </Row>

      <Row>

      <Form.Group as={Col} className="mb-3" >
        
        <Form.Label>Mother's Income:</Form.Label>
        <Form.Control
          type="text" 
          onChange={(e) => setMotherIncome(e.target.value)} 
          value={motherIncome}
        />
        
      </Form.Group>

      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>How many siblings do you have:</Form.Label>
        <Form.Control
          required 
          type="number" 
          onChange={(e) => setSiblings(e.target.value)} 
          value={siblings}
        />
        
      </Form.Group>
      </Row>
      <Row>


      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>Sibling details (Name and Occupation):</Form.Label>
        <Form.Control as="textarea"
           
          onChange={(e) => setSiblingDetails(e.target.value)} 
          value={siblingDetails}
        />
        
      </Form.Group>

      <Form.Group as={Col}  className="mb-3" >

        
        
        <Form.Label>Guardian's Name (Optional):</Form.Label>
        <Form.Control
          type="text" 
          onChange={(e) => setGuardian(e.target.value)} 
          value={guardian}
        />
        
      </Form.Group>

    </Row>
    <Row>



      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>Guardian's Occupation (Optional):</Form.Label>
        <Form.Control 
          type="text" 
          onChange={(e) => setGuardianOccupation(e.target.value)} 
          value={guardianOccupation}
        />
        
      </Form.Group>

      <Form.Group as={Col} className="mb-3" >
        
        <Form.Label>Guardian's Income (Optional):</Form.Label>
        <Form.Control
           
          type="text" 
          onChange={(e) => setGuardianIncome(e.target.value)} 
          value={guardianIncome}
        />
        
      </Form.Group>
      </Row>
      <Row>

      <p style={{textAlign:"center", fontSize:"20px"}}><strong>Education Details</strong></p>
      <hr></hr>


      <Form.Group  as={Col} className="mb-3" >
        
        <Form.Label>What course will the scholarship be used for:</Form.Label>
        <Form.Control
          required 
          type="text" 
          onChange={(e) => setScholarshipUsage(e.target.value)} 
          value={scholarshipUsage}
        />
        
      </Form.Group>

      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>What year are you currently studying in : </Form.Label>
        <Form.Select name="years" id="years" onChange={(e)=>setYear(e.target.value)} value={year}>
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
        </Form.Select>
        
      </Form.Group>
      </Row>

      <Row>

      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>Name of the college and address:</Form.Label>
        <Form.Control
          required 
          type="text" 
          onChange={(e) => setCollege(e.target.value)} 
          value={college}
        />
        
      </Form.Group>

      <Form.Group  as={Col} className="mb-3" >
        
        <Form.Label>Previous academic course:</Form.Label>
        <Form.Control
          required 
          type="text" 
          onChange={(e) => setPrevious(e.target.value)} 
          value={previous}
        />
        
      </Form.Group>
      </Row>
      <Row>


      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>Previous academic course percentage:</Form.Label>
        <Form.Control
          required 
          type="text" 
          onChange={(e) => setPreviousPercentage(e.target.value)} 
          value={previousPercentage}
        />
        
      </Form.Group>

      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>Total fees of the current academic year:</Form.Label>
        <Form.Control
          required 
          type="text" 
          onChange={(e) => setTotalFees(e.target.value)} 
          value={totalFees}
        />

        
      </Form.Group>
      </Row>
      <Row>


      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>Fees of paid as of now:</Form.Label>
        <Form.Control
          required 
          type="text" 
          onChange={(e) => setFeesPaid(e.target.value)} 
          value={feesPaid}
        />
        
      </Form.Group>

      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>Entrance exam ranking (Optional). Mention which exam:</Form.Label>
        <Form.Control
           
          type="text" 
          onChange={(e) => setEntranceRank(e.target.value)} 
          value={entranceRank}
        />
        
      </Form.Group>
      </Row>
      <Row>
      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>Any other Scholarship Awarded (If yes, mention which one and the amount given):</Form.Label>
        <Form.Control
          type="text" 
          onChange={(e) => setOtherScholarship(e.target.value)} 
          value={otherScholarship}
        />
        
      </Form.Group>
      </Row>
      <Row>



      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>Do you have a letter of financial aid from the college:</Form.Label>
        <select onChange={(e)=>setLetter(e.target.value)}>
          <option>Yes</option>
          <option>No</option>
        </select>
        
      </Form.Group>

      
      {letter==="Yes" &&
       <Form.Group as={Col}  className="mb-3" >
         
        <Form.Label>Letter from college detailing need for financial assistance:</Form.Label>
        <Form.Control 
          
          type="file"
          onChange={handleFinAidFileChange}
        />
        {finError&&<p>{finError}</p>}
        
      </Form.Group>


      }

      </Row>
      <Row>

      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>I deserve the scholarship because:</Form.Label>
        <Form.Control as="textarea"
          required 
          onChange={(e) => setExplanation(e.target.value)} 
          value={explanation}
        />
        
      </Form.Group>


      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>Please elaborate on your family's financial condition:</Form.Label>
        <Form.Control as="textarea"
          required 
          onChange={(e) => setFamilyFinancialDetails(e.target.value)} 
          value={familyFinancialDetails}
        />
        
      
      </Form.Group>
      </Row>
      <Row>


      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>Reference 1: Please provide name, address, and contact number:</Form.Label>
        <Form.Control
          required 
          type="text" 
          onChange={(e) => setReference1(e.target.value)} 
          value={reference1}
        />
        
      </Form.Group>

      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>Reference 2: Please provide name, address, and contact number:</Form.Label>
        <Form.Control
          required 
          type="text" 
          onChange={(e) => setReference2(e.target.value)} 
          value={reference2}
        />
        
      </Form.Group>
      </Row>

      <Row>

      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>Marksheet of Previous Two Years (Year 1):</Form.Label>
        <Form.Control 
          
          type="file"
          onChange={handleMarksheetFileChange}
        />
        {markseetError && <p class="error">{markseetError}</p>}
        
      </Form.Group>

      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>Marksheet of Previous Two Years (Year 2) :</Form.Label>
        <Form.Control 
          
          type="file"
          onChange={handleMarksheet2FileChange}
        />
        {markseet2Error && <p class="error">{markseet2Error}</p>}
        
      </Form.Group>
      </Row>

      <Row>

      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>EWS/Income certificate:</Form.Label>
        <Form.Control 
          type="file"
          onChange={handleEWSFileChange}
        />
        {ewsError&&<p class="error">{ewsError}</p>}

       
        
      </Form.Group>


     

     


      <Form.Group as={Col}  className="mb-3" >
        
        <Form.Label>Admission Details and Fees:</Form.Label>
        <Form.Control 
          
          type="file"
          onChange={handleAdmissionFileChange}
        />
        {admissionError &&<p class="error">{admissionError}</p>}
        
      </Form.Group>
      </Row>


      <Row>
      <Form.Group >
        
        <label>Do you agree to be a part of H&L's Database for Students:<span>&#160;</span>
        <select  onChange={(e)=>setDbAgree(e.target.value)} value={dbAgree}>
          <option>Yes</option>
          <option>No</option>
        </select >
        </label>
      </Form.Group>
      <Form.Group as={Col} className="mb-3" >
      <label for="checkbox1"></label>
      <Form.Check  required type="checkbox" label="I declare that all the details furnished above is true according to my knowledge. I agree that if any discrepancies found with the details furnished, I will return the amount obtained by me."></Form.Check>
      </Form.Group>
      

      
      
      </Row>


      
      {genError && <div className="error">{genError}</div>}
      {!(finError!==null || admissionError!==null  || markseetError!==null || markseet2Error!==null  || ewsError!==null ) && ((password===null && confirm_password===null) || password===confirm_password)  && !isPending && <Button type="submit" className="primary">Sign up</Button>}
      {(finError!==null || admissionError!==null || markseetError!==null || markseet2Error!==null   || ewsError!==null ) && <p class="error">There was an error with one of the files. Please try a different file or compress it.</p> }
      {password && confirm_password&& password!==confirm_password && <p class="error">Please ensure that the password and the confirm password fields match.</p>}
      {isPending && <button className="btn" disabled>Loading...Do Not Refresh The Page</button>}
      {error && <div className="error">{error}</div>}
    </Form>
    </>
  )
}

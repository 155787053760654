
import { useState, useEffect } from 'react'
import { projectAuth, projectStorage, projectFirestore } from '../firebase/config'

import  {useHistory}  from 'react-router'
export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const history=useHistory()

  const signup = async (email, password, displayName, EWScert,finAidCert,admissionCert,aadhar,agreement,dateOfBirth,reference1,reference2,familyFinancialDetails,explanation,letter,entranceRank,totalFees,feesPaid,previous,previousPercentage,year,college,scholarshipUsage,guardian,guardianIncome,guardianOccupation,motherIncome,motherOccupation,motherName,fatherName,fatherIncome,fatherOccupation,siblingDetails,siblings,phone,whatsappNumber,permanentAddress,correspondenceAddress,pdf,pdf2,type,status,dbAgree,amount,dateOfApproval,mode,otherScholarship) => {
    setError(null)
    setIsPending(true)
    
    try {
      // signup
      const res = await projectAuth.createUserWithEmailAndPassword(email, password)

      if (!res) {
        throw new Error('Could not complete signup')
      }

      // upload user thumbnail

    
      let marksheetUrl="empty"
      if (pdf){
        const marksheet_uploadPath = `marksheets/${res.user.uid}/${pdf.name}`
        const pdf_marksheet = await projectStorage.ref(marksheet_uploadPath).put(pdf)
        marksheetUrl = await pdf_marksheet.ref.getDownloadURL()

      }

     let marksheetUrl2="empty"
      if (pdf2){
        const marksheet2_uploadPath = `marksheets/${res.user.uid}/${pdf2.name}`
      const pdf_marksheet2 = await projectStorage.ref(marksheet2_uploadPath).put(pdf2)
       marksheetUrl2 = await pdf_marksheet2.ref.getDownloadURL()
      }
      
      let ewsUrl="empty"
      if (EWScert){
        const ews_uploadPath = `EWS/${res.user.uid}/${EWScert.name}`
        const pdf_ews = await projectStorage.ref(ews_uploadPath).put(EWScert)
         ewsUrl = await pdf_ews.ref.getDownloadURL()
      }
     


     
      let finaidUrl = "empty"
      if(letter==="yes"){
        const financial_aid_uploadPath = `Financial Aid/${res.user.uid}/${finAidCert.name}`
        const pdf_finaid = await projectStorage.ref(financial_aid_uploadPath).put(finAidCert)
        finaidUrl = await pdf_finaid.ref.getDownloadURL()
      }
      
      let admissionUrl="empty"
      if (admissionCert){
        const admission_uploadPath= `Admission/${res.user.uid}/${admissionCert.name}`
        const pdf_admission = await projectStorage.ref(admission_uploadPath).put(admissionCert)
         admissionUrl = await pdf_admission.ref.getDownloadURL()
      }
   




      // add display AND PHOTO_URL name to user
      await res.user.updateProfile({ displayName })

      // create a user document
      await projectFirestore.collection('users').doc(res.user.uid).set({
        displayName,
        scholarshipUsage,
        year,     
        explanation,
        type:type,
        status,
        dbAgree,
        amount,
        mode,
        dateOfApproval,
      })

      console.log('ckear')

      await projectFirestore.collection('users').doc(res.user.uid).collection('private').doc(res.user.uid).set({
        email:email, 
        aadhar,
        dateOfBirth,
        correspondenceAddress,
        permanentAddress,
        phone,
        whatsappNumber,
        fatherName,
        fatherIncome,
        fatherOccupation,
        motherName,
        motherIncome,
        motherOccupation,
        siblings,
        siblingDetails,
        guardian,
        guardianIncome,
        guardianOccupation,
        college,
        previous,
        previousPercentage,
        agreement,
        totalFees,
        feesPaid,
        entranceRank,
        otherScholarship,

        letter,
        familyFinancialDetails,
        reference1,
        reference2,

        marksheetURL:marksheetUrl,
        marksheetURL2:marksheetUrl2,

        EWS_URL:ewsUrl,
        FIN_AID_URL:finaidUrl,
        Admission_URL:admissionUrl,


      });

      
      await res.user.sendEmailVerification()
      .then(() => {
        projectAuth.signOut()
        history.push('/success')
      }).catch(alert)
      // dispatch login action
      // dispatch({ type: 'LOGIN', payload: res.user })

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
    } 
    catch(err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { signup, error, isPending }
}
import './List.css'
import ProjectList from '../../components/ScholarshipList'
import { useCollection } from '../../hooks/useCollection'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
// components

export default function List(){
    const { documents, error } = useCollection('projects')

    
    return(

        <div>
            {error && <p>{error}</p>}
            {/* <Breadcrumb>
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item active >
        Scholarship List
    </Breadcrumb.Item>
    </Breadcrumb>
            <h1>Available Scholarships</h1>
     */}
            {documents && <ProjectList projects={documents} />}
        </div>
               

    )
    }
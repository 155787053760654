import { useState} from 'react'
import { useFirestore } from '../../hooks/useFirestore'
import { useHistory} from 'react-router'

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row'

import Button from 'react-bootstrap/Button'

// styles
import './Create.css'



export default function Create() {
  const history = useHistory()
  const { addDocument, response } = useFirestore('projects')

  // form field values
  const [name, setName] = useState('')
  const [formError, setFormError] = useState(null)
  const [website,setWebsite]=useState(null)
  const [applicable,setApplicable]=useState(null)

  // create user values for react-select


  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError(null)

    


    const project = {
      name,
      website,
      applicable
    }

    await addDocument(project)
    if (!response.error) {
      history.push('/')
    }
  }

  return (
    <div className="create-form">
      <h2 className="page-title">Create a new Scholarship</h2>
      <Form onSubmit={handleSubmit}>
      <Row>
      
      <Form.Group as={Col} className="mb-3">
        <Form.Label>Scholarship Name: </Form.Label>
        <Form.Control
          required 
          type="text" 
          onChange={(e) => setName(e.target.value)} 
          value={name}
        />
      </Form.Group>
        
      
     
      
      
      <Form.Group as={Col} className="mb-3">
        <Form.Label>Website: </Form.Label>
        <Form.Control
          type="text" 
          onChange={(e) => setWebsite(e.target.value)} 
          value={website}
        />

      </Form.Group>
      </Row>
      <Row>

        <Form.Group as={Col} className="mb-3">

        <Form.Label>Applicable For: </Form.Label>
        <Form.Control
          type="text" 
          onChange={(e) => setApplicable(e.target.value)} 
          value={applicable}
        />

      </Form.Group>
      
      </Row>
        
       

        <Button type="submit" className="primary">Add Project</Button>

        {formError && <p className="error">{formError}</p>}
      </Form>
    </div>
  )
}

import React from 'react'
import { useDocument } from '../hooks/useDocument'
import Create from '../pages/create/Create'
import { Redirect } from 'react-router'
import { useAuthContext } from '../hooks/useAuthContext'
export default   function  CreateRedirect({id}) {
    const {user}=useAuthContext()
    const {document,error}=useDocument('users',user.uid)
    if (document){
        console.log(document.type)
        if(document.type==='admin'){
            return <Create path='/create'></Create>
        }else if(document.type!=='admin'){
            return <Redirect to='/'></Redirect>
        }
    }else if (error){
        console.log(error)
        
       
    } else{
        return null
    }
}

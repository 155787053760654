import React from 'react'
import { useParams } from 'react-router-dom'
import {Link} from 'react-router-dom'
export default function Success() {
  const {id}=useParams()
  return (
    <div>

        <p>Success! Your application has been submitted.</p>
        <p>We have now sent a verification email to your email ID. Please verify your email in order to log in and access your portal.</p>
        <Link to="/login">Back To Login</Link>
    </div>
  )
}

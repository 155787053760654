import React from 'react'
import { useDocument } from '../hooks/useDocument'
import Update from '../pages/create/Update'
import { Redirect } from 'react-router'
import { useAuthContext } from '../hooks/useAuthContext'
export default   function  UpdateRedirect({id}) {
    const {user}=useAuthContext()
    const {document,error}=useDocument('users',user.uid)
    if (document){
        console.log(document.type)
        if(document.type==='admin'){
            return <Update path='/update/:id'></Update>
        }else if(document.type!=='admin'){
            return <Redirect to='/'></Redirect>
        }
    }else if (error){
        console.log(error)
        return(<Redirect to="/"></Redirect>)
        
    }else{
        return null
    }
   
}

import { useState } from 'react';
import { useFirestore } from "../../hooks/useFirestore";
import { Link, useHistory } from 'react-router-dom';
import { useAuthContext } from "../../hooks/useAuthContext";
import { useParams } from 'react-router-dom';
import UpdateScholarship from "./UpdateScholarship";
import { Card } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';



export default function ProjectSummary({ project }) {
  const { id } = useParams();
  const { user } = useAuthContext();
  
  const handleBrowseWebsite = () => {
    window.open(project.website, "_blank");
  }

  return (
    <>
   
    <div>
      <style>
        {`
          @media (max-width: 576px) {
            .custom-card {
              max-width: 25rem;
            }
          }
  
          @media (min-width: 577px) {
            .custom-card {
              max-width: 50rem;
            }
          }
        `}
      </style>
      <Breadcrumb>
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item href="/list">
        Scholarship List
      </Breadcrumb.Item>
      <Breadcrumb.Item active>Scholarship Details</Breadcrumb.Item>
    </Breadcrumb>
      <div style={{paddingTop:"2rem"}}>

     
      <Card className="custom-card" style={{ paddingTop:"3px" }}>
        <Card.Body>
          <Card.Title>{project.name}</Card.Title>
          <Card.Text style={{ wordWrap: 'break-word', maxHeight: "150px", overflowY: "auto" }}>
            Details: {project.details}
          </Card.Text>
          <Card.Text>Applicable For: {project.applicable}</Card.Text>
          {project.website && <Card.Text>
            Website: <Link to="#" onClick={handleBrowseWebsite}>{project.website}</Link>
          </Card.Text>}
        </Card.Body>
      </Card>
      </div>
  
      {user && <UpdateScholarship user={user} project={project} />}
    </div>
    </>
  );
}

import React from 'react'
import { useDocument } from '../hooks/useDocument'
import StudentList from '../pages/Student_List/studentList'
import { useAuthContext } from '../hooks/useAuthContext'
import { Redirect } from 'react-router'


export default function StudentListRedirect(){
    const {user}=useAuthContext()
    const {document,error}=useDocument('users',user.uid)
    if (document){
        if(document.type==="admin"){
            return <StudentList></StudentList>}
        else{
            return null
        }
    }else if(error){
        console.log(error)
        return(<Redirect to="/"></Redirect>)

    }
    else{
        return null
    }



}
import { Link } from 'react-router-dom'
// styles
import { useDocument } from '../../hooks/useDocument'
import { useState } from 'react'
import Card from 'react-bootstrap/Card'

import { useHistory } from 'react-router'

import { Button,ButtonGroup } from 'react-bootstrap'
import Update_User from '../update/Update_User'
import UserDetails from './UserDetails'
const filterList=['Application Status', 'My Application','Update Application']


export default function Home({ user }) {
  const { document, error } = useDocument("users", user.uid)
  const history = useHistory()
  const [currentFilter, setCurrentFilter] = useState('Application Status');

  const changeFilter=(newFilter)=>{
    setCurrentFilter(newFilter)
  }

  const formatAsDDMMYYYY = (date) => {
    const dateObj = new Date(date);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div >
      <div className='project-filter'>
      <ButtonGroup>
        {filterList.map((f) => (
          <Button
            key={f}
           onClick={(e)=>setCurrentFilter(e.target.value)}
            variant={ 'primary' }
            value={f}

          >
            {f}
          </Button>
        ))}
      </ButtonGroup>
      </div>
      
    { error && <p>{error}</p>}
      {document && currentFilter==='Application Status' && <Card style={{ width: '25rem' }}>
                    <Card.Body>
                      <Card.Title>
                        <p>Name: {document.displayName}</p>
                      </Card.Title>
                      <Card.Text>
                        <p>Status: {document.status}</p>
                        {document.status === 'Approved' && (
                          <div>
                            <p>Amount: {document.amount}</p>
                            <p>Date Of Approval: {document.dateOfApproval && formatAsDDMMYYYY(document.dateOfApproval)}</p>
                            <p>Mode: {document.mode}</p>
                          </div>
                        )}
                      </Card.Text>
                      
                    </Card.Body>
                  </Card>}
      {document && currentFilter==="My Application" && <UserDetails></UserDetails>}
      {document && currentFilter==="Update Application" && <Update_User changeFilter={changeFilter}></Update_User>}

         {document && document.type==="admin" && (history.push("/studentlist"))}         
    </div>
  )
}


import { useParams } from 'react-router'
// styles
import './Create.css'
import { useDocument } from '../../hooks/useDocument'
import UpdateScholarship from './UpdateScholarship'



export default function Update() {
  const {id}=useParams()
  console.log(id)
  const {document,error}=useDocument('projects',id)
  
 

  

  return (
    <div>
      {error && <p>{error}</p>}
      {document && <UpdateScholarship document={document}></UpdateScholarship>}
    </div>
  )
}

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'
import List from './pages/List/List'
import UpdateRedirect from './components/UpdateRedirect'
// styles
import './App.css'
import CreateRedirect from './components/CreateRedirect'
// pages & components
import Dashboard from './pages/dashboard/Dashboard'
import StudentDetail from './pages/Student_List/StudentDetail'
import Login from './pages/login/Login'
import Project from './pages/project/Scholarship'
import Navbar from './components/Navbar_Home'
import Sidebar from './components/Sidebar'
import AdminLogin from './pages/login/AdminLogin'
import StudentListRedirect from './components/StudentListRedirect'
import Update_User from './pages/update/Update_User'
import UserDetails from './pages/dashboard/UserDetails'
import UpdateStatusRedirect from './components/UpdateStatusRedirect'
import Preload from './pages/signup/Preload'
import PublicList from './pages/List/PublicList'
import Success from './pages/success/Success'
import ForgotPassword from "./pages/login/ForgotPassword"
// import Admin from './components/Admin'
function App() {
  const { authIsReady, user } = useAuthContext()
  // console.log(user.uid)
   


  return (
    <div className="App" >
      {authIsReady && (
        <BrowserRouter>

          {user && <><Sidebar /></>}
          
          <div className="container" style={{ width: '100vw', overflowX: 'hidden' }}>
            <Navbar />
            <Switch>
              <Route exact path="/">
                
                 <Dashboard />
              </Route>
              <Route path="/create">
                 {user && <CreateRedirect id={user.uid}></CreateRedirect>}
                 {!user && <Redirect to="/" />}
              </Route>
              <Route path="/success">
                 {user && <Redirect to='/'></Redirect>}
                 {!user && <Success></Success>}
              </Route>

            <Route path="/forgot-password">
                {user && <Redirect to="/" /> }
                {!user && <ForgotPassword /> }
              </Route>
          <Route path="/preload">
            {!user && <Preload></Preload>}
            {user && <Redirect to="/"></Redirect>}
          </Route>
          <Route path="/list">
            <List></List>
          </Route> 
          <Route path="/publiclist">
            <PublicList></PublicList>
          </Route> 
          <Route path="/studentlist/:id/update">
            {user && <UpdateStatusRedirect></UpdateStatusRedirect>}

          </Route>
          <Route path="/studentlist/:id">
                {user && <StudentDetail></StudentDetail>}
          </Route>
          
          <Route path="/studentlist">
              {user && <StudentListRedirect></StudentListRedirect>}
              {!user && <Redirect to="/"></Redirect>}
          </Route>
             
              <Route path="/projects/:id">
                <Project />
              </Route>
{/*              
              <Route path='/update_user/:id'> 
                  {user && <Update_User id={user.uid}></Update_User>}
              </Route> */}
              <Route path='/update/:id'>
                {user && <UpdateRedirect id={user.uid}></UpdateRedirect>}
                {!user && <Redirect to='/'></Redirect>}
              </Route>
              <Route path='/details/:id/update'>
                {user && <Update_User></Update_User>}
                {!user && <Redirect to='/'></Redirect>}
              </Route>
              <Route path='/details/:id'>
                {user && <UserDetails></UserDetails>}
                {!user && <Redirect to='/'></Redirect>}
              </Route>
              
              <Route path='/adminlogin'>
                {user && <Redirect to="/" />}
                {!user && <AdminLogin />}
              </Route>
             
              <Route path="/login">
                {user && <Redirect to="/" /> }
                {!user && <Login /> }
              </Route>
              
              
              
              
            </Switch>
          </div>
        </BrowserRouter>
      )}

    </div>
  );
}

export default App
import { useAuthContext } from "../hooks/useAuthContext";
import { useDocument } from "../hooks/useDocument";
import UpdateStatus from "../pages/Student_List/UpdateStatus"
import { Redirect } from 'react-router'

export default function UpdateStatusRedirect(){
    const {user}=useAuthContext()
    const {document,error}=useDocument("users",user.uid)

    
    if (document && document.type==="admin"){
        return(
            <UpdateStatus></UpdateStatus>
        )
    }else if(error){
        console.log(error)
        return(<Redirect to="/"></Redirect>)
    }else{
        return null
    }
    


}
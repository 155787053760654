
import { useDocument } from "../../hooks/useDocument";
import { useAuthContext } from "../../hooks/useAuthContext";
import { usePrivate } from "../../hooks/usePrivate";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button'
import {Link} from "react-router-dom"
export default function UserDetails(){

    const {user}=useAuthContext()
    const {document,error}=useDocument("users",user.uid)
    const {privateDocument,privateError}=usePrivate(user.uid)

  const formatAsDDMMYYYY = (date) => {
   const dateObj = new Date(date);
   const day = String(dateObj.getDate()).padStart(2, '0');
   const month = String(dateObj.getMonth() + 1).padStart(2, '0');
   const year = dateObj.getFullYear();
   return `${day}-${month}-${year}`;
 };
  

    if (document && privateDocument){
        return(
            <div >
          {error && <tr>{error}</tr>}
      <p>Below are the details of your application :</p>
       <Table striped>
      
       <tbody>
       
        <tr><td>Name:</td> <td>{document.displayName}</td></tr>
        <tr><td>Email:</td> <td>{privateDocument.email}</td></tr>
        <tr><td>Status:  
</td> <td>{document.status}</td></tr>
        {document.status==="Approved" && <><tr><td>Amount:   </td> <td>{document.amount}</td></tr>
        <tr><td>Date Of Approval:   </td> <td>{document.dateOfApproval && formatAsDDMMYYYY(document.dateOfApproval)}</td></tr><tr><td>Mode</td><td>{document.mode}</td></tr></>}

       
        <tr><td>Date of Birth:</td><td>{privateDocument.dateOfBirth && formatAsDDMMYYYY(privateDocument.dateOfBirth)} </td></tr>
        <tr><td>Correspondence Address:</td> <td>{privateDocument.correspondenceAddress}</td></tr>
        <tr><td>Permanent Address:</td> <td>{privateDocument.permanentAddress}</td></tr>
        <tr><td>Phone Number:</td> <td>{privateDocument.phone}</td></tr>
        <tr><td>Whatsapp Number:</td> <td>{privateDocument.whatsappNumber}</td></tr>
        <tr><td>Orphan: </td><td>{privateDocument.orphan}</td></tr>
        <tr><td>Father's Name:</td> <td>{privateDocument.fatherName}</td></tr>
        <tr><td>Father's Occupation:</td> <td>{privateDocument.fatherOccupation}</td></tr>
        <tr><td>Father's Income:</td> <td>{privateDocument.fatherIncome}</td></tr>
        <tr><td>Mother's Name:</td> <td>{privateDocument.motherName}</td></tr>
        <tr><td>Mother's Occupation:</td><td>{privateDocument.motherOccupation}</td></tr>
        <tr><td>Mother's Income:</td><td>{privateDocument.motherIncome}</td></tr>
        <tr><td>Number of Siblings: </td><td>{privateDocument.siblingDetails}</td></tr>
        <tr><td>Sibling Detail:</td> <td>{privateDocument.siblings}</td></tr>
        <tr><td>Guardian Name:</td> <td>{privateDocument.guardian}</td></tr>
        <tr><td>Guardian's Occupation: </td><td>{privateDocument.guardianOccupation}</td></tr>
        <tr><td>Guardian's Income: </td>{privateDocument.guardianIncome} <td></td></tr>
        <tr><td>Scholarship Usge:</td> <td>{document.scholarshipUsage}</td></tr>
        <tr><td>Year of Study: </td><td>{document.year}</td></tr>
        <tr><td>College of Study:</td><td>{privateDocument.college}</td> </tr>
        <tr><td>Previous academic course: </td><td>{privateDocument.previous}</td></tr>
        <tr><td>Previous academic course percentage</td><td>{privateDocument.previousPercentage}</td></tr>
        <tr><td>Total Fees:</td> <td>{privateDocument.totalFees}</td></tr>
        <tr><td>Fees Paid:</td><td>{privateDocument.feesPaid}</td></tr>
        <tr><td>Entrance Rank: </td><td>{privateDocument.entranceRank}</td></tr>
        <tr><td>Other scholarship : </td><td>{privateDocument.otherScholarship}</td></tr>

        <tr><td>Why They Deserve:</td><td>{document.explanation}</td> </tr>
        <tr><td>Family Financial Details:</td><td>{privateDocument.familyFinancialDetails}</td> </tr>
        <tr><td>Reference 1: </td><td>{privateDocument.reference1}</td></tr>
        <tr><td>Reference 2: </td><td>{privateDocument.reference2}</td></tr>
        {privateDocument.EWS_URL!="empty" && <tr><td>EWS Certifcate: </td><td><a target="_blank" href={privateDocument.EWS_URL}>EWS Certificate</a></td></tr>}
         {privateDocument.marksheetURL!="empty" && <tr><td>Marksheet (Year 1): </td><td><a  target="_blank" href={privateDocument.marksheetURL}>Marksheet Year 1</a></td></tr>}
         {privateDocument.marksheetURL!="empty" &&<tr><td>Marksheet (Year 2): </td><td><a  target="_blank" href={privateDocument.marksheetURL2}>Marksheet Year 2</a></td></tr>}

        {privateDocument.FIN_AID_URL!="empty" && <tr><td>Financial Aid Certificate</td><a target="_blank" href={privateDocument.FIN_AID_URL}>Financial Aid Certificate</a><td></td></tr>}
        {privateDocument.Admission_URL!="empty" &&<tr><td>Admission Certificate: </td><td><a target="_blank" href={privateDocument.Admission_URL}>Admission Certificate</a></td></tr>}
        {/* <Link to={`/studentlist/${user.uid}/update`}><Button class="primary">Edit Status</Button></Link> */}


       




        

        
        



        </tbody>

        </Table >
        </div>
         )
     }
     else{
        return null
     }



}
import { useState,useEffect } from 'react';
import { projectFirestore } from '../firebase/config';

export const  usePrivate=(id) => {
    const [privateDocument, setPrivateDocument] = useState(null);
    console.log('works')

    const [privateError,setPrivateError]=useState(null)
   
    useEffect(() => {

        
      const ref =  projectFirestore.collection('users').doc(id).collection('private').doc(id);
        
      const unsubscribe = ref.onSnapshot(snapshot => {
          // need to make sure the doc exists & has data
          if(snapshot.data()) {
            setPrivateDocument({...snapshot.data(), id: snapshot.id})
            console.log('works')
            setPrivateError(null)
          }
            else {
              setPrivateError('No such document exists')
              console.log('works not')

            }
          }, err => {
            console.log(err.message)
            setPrivateError('failed to get document')
          })
            
          
        return () => unsubscribe()

        
    }, [id])
    return {privateDocument,privateError}
}


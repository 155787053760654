import {  useState } from 'react'
import { useDocument } from '../../hooks/useDocument'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useFirestore } from '../../hooks/useFirestore'
import { useHistory} from 'react-router'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

export default function Update_User(){

    const { user } = useAuthContext()
    const { updateDocument, response } = useFirestore('users')
    const {document,error}=useDocument('users',user.uid)
   
    const [dbAgree,setDbAgree]=useState()
 
    const history = useHistory()


   
   const handleSubmit=async (e)=>{

    e.preventDefault()
    const new_user={

      dbAgree,
  }

    await updateDocument(user.uid,new_user)
    window.location.reload();


   }

    return(
      <>
      {error && <p>{error}</p>}
      {document && 
        <div style={{width:"30rem"}}>
        
         
     
      
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">

         
          <Form.Label>Do you wish to be a part of H&L's Public Database, selecting Yes indicates that your details will be displayed in the Students list Page for the Donors to find. 
 </Form.Label>

          <Form.Select onChange={(e)=>setDbAgree(e.target.value)} value={dbAgree} >
            <option>{document.dbAgree}</option>
            {document.dbAgree!="Yes" && <option>Yes</option>}
            {document.dbAgree!="No" && <option>No</option>}s


          </Form.Select>
          </Form.Group>
          <Button class="primary" type="submit" >Submit</Button>


        </Form>
       
        </div>}

        </>
    )

}
import { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useDocument } from '../../hooks/useDocument';
import { useFirestore } from '../../hooks/useFirestore';
import { useParams } from 'react-router';

import { useHistory} from 'react-router'

export default function UpdateStatus(){
    const [status, setStatus] =  useState('')
    const [amount, setAmount] = useState('')
    const [mode, setMode] = useState('')

    const [dateOfApproval, setDateOfApproval] = useState('')


    const {id}=useParams()
    const history = useHistory()
    const { updateDocument, response } = useFirestore('users')
    const {document,error}=useDocument('users',id)


    const handleSubmit=async e=>{
        e.preventDefault()

        const new_user={

            status,
            dateOfApproval,
            amount,
            mode
        }
        console.log(new_user.status)
        await updateDocument(id,new_user)
        if (!response.error){
            console.log(document.status)
            history.push(`/studentlist/${id}`)
        }else{
            console.log(error)
        }
        
    }
    return(
        <Form onSubmit={handleSubmit}>
      {error && <p>{error}</p>}

      <Row>
        <Col md={6}>
          <Form.Group class="mb-3">
            <Form.Label>New Status</Form.Label>
            <Form.Control
              as="select"
              required
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option>{document && document.status}</option>
              {document && document.status !== 'Pending' && <option>Pending</option>}
              {document && document.status !== 'Approved' && <option>Approved</option>}
              {document && document.status !== 'Denied' && <option>Denied</option>}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      {status === 'Approved' && (
        <Row>
          <Col md={6}>
            <Form.Group class="mb-3">
              <Form.Label>Mode</Form.Label>
              <Form.Control type="text" onChange={(e) => setMode(e.target.value)} value={mode} />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group class="mb-3">
              <Form.Label>Date of Approval:</Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => setDateOfApproval(e.target.value)}
                value={dateOfApproval}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group class="mb-3">
              <Form.Label>Amount:</Form.Label>
              <Form.Control type="text" onChange={(e) => setAmount(e.target.value)} value={amount} />
            </Form.Group>
          </Col>
        </Row>
      )}
    <Form.Group class="mb-3"> <Button className="btn" type="submit">
        Update Status
      </Button></Form.Group>
      
    </Form>
    )
    



    }
 
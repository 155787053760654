
import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirestore"
import { Link, useHistory } from 'react-router-dom'
import { useAuthContext } from "../../hooks/useAuthContext"
import {useParams} from 'react-router-dom'

export default function UpdateScholarship({user, project}){
  const {id}=useParams()
  const {document,error}=useDocument('users',user.uid)
  const { deleteDocument } = useFirestore('projects')

  let flag=false;
  if (document){
    console.log("hi")
    if(document.type=="admin"){
      flag=true;
    }
  }

  const history = useHistory()

  const handleClick = () => {
    deleteDocument(project.id)
    history.push('/list')
  }

  return(
    <div>
    {user && flag &&   (
        <>
        <button className="btn" onClick={handleClick}>Delete</button>
        <button className='btn'><Link to={`/update/${id}`}>Update</Link></button>
        </>
      )}
    </div>
  )

}
import { useParams } from "react-router-dom"
import { useDocument } from '../../hooks/useDocument'
import { Link } from 'react-router-dom'
import {usePrivate} from "../../hooks/usePrivate"
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form'
import { useState } from 'react';
import {  projectStorage } from '../../firebase/config'
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import firebase from 'firebase/app'; // Import Firebase
import 'firebase/firestore'; // Import Firestore module from Firebase


import Button from 'react-bootstrap/Button'


export default function StudentDetail({student}){
  const {id}=useParams()
//   const history = useHistory()

   const [isEditing, setIsEditing] = useState(false);
  const [editedField, setEditedField] = useState('');
  const [fileType, setFileType] = useState(null);
  const [file,setFile]=useState(null)

  const [editedValue, setEditedValue] = useState('');
  const firestore = firebase.firestore();

   const handleSubmit=async(e)=>{
      e.preventDefault()
      try{
      let urlVal="empty"
      let finalUrl="empty"
      console.log(fileType)

      if (fileType==="Marksheet1"){
         console.log('trial1')
         const marksheet_uploadPath = `marksheets/${id}/${file.name}`
        const pdf_marksheet = await projectStorage.ref(marksheet_uploadPath).put(file)
        finalUrl = await pdf_marksheet.ref.getDownloadURL()
        urlVal="marksheetURL"
      }

      if(fileType==="Marksheet2"){
         const marksheet2_uploadPath = `marksheets/${id}/${file.name}`
         const pdf_marksheet2 = await projectStorage.ref(marksheet2_uploadPath).put(file)
          finalUrl = await pdf_marksheet2.ref.getDownloadURL()
         urlVal="marksheetURL2"
      }

      if(fileType==="EWS"){
         const ews_uploadPath = `EWS/${id}/${file.name}`
         const pdf_ews = await projectStorage.ref(ews_uploadPath).put(file)
         finalUrl = await pdf_ews.ref.getDownloadURL()
         urlVal="EWS_URL"
      }

      if(fileType==="FinAid"){
         const financial_aid_uploadPath = `Financial Aid/${id}/${file.name}`
        const pdf_finaid = await projectStorage.ref(financial_aid_uploadPath).put(file)
         finalUrl = await pdf_finaid.ref.getDownloadURL()
        urlVal="FIN_AID_URL"
       
      }

      if(fileType==="Admission"){
         const admission_uploadPath= `Admission/${id}/${file.name}`
         const pdf_admission = await projectStorage.ref(admission_uploadPath).put(file)
         finalUrl = await pdf_admission.ref.getDownloadURL()
         urlVal="Admission_URL"
      }
      
      if(finalUrl!=="empty" && urlVal!=="empty"){
      await firestore.collection('users').doc(id).collection('private').doc(id).update({
         [urlVal]:finalUrl
      })}else{
         setFileType(null)
         console.log('wrong file type chosnet')
      }

   setFileType(null)
   }catch(error){
      console.log(error)
      setFileType(null)
   }
   }

  const handleEdit = (field, value) => {
   setIsEditing(true);
   setEditedField(field);
   setEditedValue(value);
 };

 const handleCancelEdit = () => {
   setIsEditing(false);
   setEditedField('');
   setEditedValue('');
 };

 const handleSavePrivate = async () => {
   try {
     setIsEditing(false);

     // Update the Firestore document with the new value
     await firestore.collection('users').doc(id).collection('private').doc(id).update({
       [editedField]: editedValue,
     });

     console.log('cool')

   
   } catch (error) {
     console.error('Error updating document:', error);
     
     // Handle the error if the update fails
   }
 }
 const handleFileUpdate=(type)=>{
   if(type==="EWS"){
      setFileType('EWS')
   }
   if(type==="Marksheet1"){
      setFileType('Marksheet1')
   }
   if(type==="Marksheet2"){
      setFileType('Marksheet2')
   }

   if(type==="FinAid"){
      setFileType('FinAid')
   }
   if(type==="Admission"){
      setFileType('Admission')
   }

   if(type==="cancel"){
      setFileType(null)
   }
 }
 const handleSave = async () => {
   try {
     setIsEditing(false);

     // Update the Firestore document with the new value
     await firestore.collection('users').doc(id).update({
       [editedField]: editedValue,
     });

   
   } catch (error) {
     console.error('Error updating document:', error);
     // Handle the error if the update fails
   }
 };

 const handleInputChange = (event) => {
   setEditedValue(event.target.value);
 };

 const renderValue = (field, value) => {
   if (isEditing && editedField === field) {
     return (
       <>
         <input type="text" value={editedValue} onChange={handleInputChange} />
         <Button variant="primary" style={{marginRight:"2px",marginLeft:"2px"}} size="sm" onClick={handleSave}>
           Save
         </Button>
         <Button variant="primary" size="sm" onClick={handleCancelEdit}>
           Cancel
         </Button>
       </>
     );
   } else {
     return (
       <>
         <span onDoubleClick={() => handleEdit(field, value)}>{value}</span>
       </>
     );
   }
 };

 const renderValuePrivate = (field, value) => {
   if (isEditing && editedField === field) {
     return (
       <>
         <input type="text" value={editedValue} onChange={handleInputChange} />
         <Button variant="primary" size="sm"  style={{marginRight:"2px",marginLeft:"2px"}} onClick={handleSavePrivate}>
           Save
         </Button>
         <Button variant="primary" size="sm"  style={{marginRight:"2px",marginLeft:"2px"}} onClick={handleCancelEdit}>
           Cancel
         </Button>
       </>
     );
   } else {
     return (
       <>
         <span onDoubleClick={() => handleEdit(field, value)}>{value}</span>
       </>
     );
   }
 };


   const {privateDocument,privateError}=usePrivate(id)
   if(privateError){
      console.log(privateError)
   }
 
  const { document, error } = useDocument('users', id)

  const formatAsDDMMYYYY = (date) => {
   const dateObj = new Date(date);
   const day = String(dateObj.getDate()).padStart(2, '0');
   const month = String(dateObj.getMonth() + 1).padStart(2, '0');
   const year = dateObj.getFullYear();
   return `${day}-${month}-${year}`;
 };
  
 if (document && privateDocument){
    return(
        <div style={{padding:"5rem"}}>
          {error && <tr>{error}</tr>}
      
       <Table striped>
       <thead>
         <tr>
            <th>Field</th>
            <th>Value</th>

         </tr>
       </thead>
       <tbody>
       
        <tr><td>Name:</td> <td>{document.displayName}</td></tr>
        <tr><td>Email:</td> <td>{privateDocument.email}</td></tr>
        <tr><td>Status:   </td> <td>{document.status}</td></tr>
        {document.status==="Approved" && <><tr><td>Amount:   </td> <td>{document.amount}</td></tr>
        <tr><td>Date Of Approval:   </td> <td>{document.dateOfApproval && formatAsDDMMYYYY(document.dateOfApproval)}</td></tr><tr><td>Mode</td><td>{document.mode}</td></tr></>}

       
        <tr><td>Date of Birth:</td><td>{privateDocument.dateOfBirth && formatAsDDMMYYYY(privateDocument.dateOfBirth)} </td></tr>
        <tr><td>Correspondence Address:</td> <td>{renderValuePrivate('correspondenceAddress', privateDocument.correspondenceAddress)}</td></tr>
        <tr><td>Permanent Address:</td> <td>{renderValuePrivate('permanentAddress',privateDocument.permanentAddress)}</td></tr>
        <tr><td>Phone Number:</td> <td>{renderValuePrivate('phone',privateDocument.phone)}</td></tr>
        <tr><td>Whatsapp Number:</td> <td>{renderValuePrivate('whatsappNumber',privateDocument.whatsappNumber)}</td></tr>
        <tr><td>Permanent Address:</td> <td>{renderValuePrivate('permanentAddress', privateDocument.permanentAddress)}</td></tr>
<tr><td>Father's Name:</td> <td>{renderValuePrivate('fatherName', privateDocument.fatherName)}</td></tr>
<tr><td>Father's Occupation:</td> <td>{renderValuePrivate('fatherOccupation', privateDocument.fatherOccupation)}</td></tr>
<tr><td>Father's Income:</td> <td>{renderValuePrivate('fatherIncome', privateDocument.fatherIncome)}</td></tr>
<tr><td>Mother's Name:</td> <td>{renderValuePrivate('motherName', privateDocument.motherName)}</td></tr>
<tr><td>Mother's Occupation:</td><td>{renderValuePrivate('motherOccupation', privateDocument.motherOccupation)}</td></tr>
<tr><td>Mother's Income:</td><td>{renderValuePrivate('motherIncome', privateDocument.motherIncome)}</td></tr>
<tr><td>Number of Siblings:</td><td>{renderValuePrivate('siblings', privateDocument.siblings)}</td></tr>
<tr><td>Sibling Detail:</td><td>{renderValuePrivate('siblingDetails', privateDocument.siblingDetails)}</td></tr>
<tr><td>Guardian Name:</td><td>{renderValuePrivate('guardian', privateDocument.guardian)}</td></tr>
<tr><td>Guardian's Occupation:</td><td>{renderValuePrivate('guardianOccupation', privateDocument.guardianOccupation)}</td></tr>
<tr><td>Guardian's Income:</td><td>{renderValuePrivate('guardianIncome', privateDocument.guardianIncome)}</td></tr>

        <tr><td>Scholarship Usge:</td> <td>{renderValue('scholarshipUsage', document.scholarshipUsage)}</td></tr>
        <tr><td>Year of Study: </td><td>{renderValue('year', document.year)}</td></tr>
        <tr><td>College of Study:</td><td>{renderValuePrivate('college', privateDocument.college)}</td></tr>
<tr><td>Previous academic course:</td><td>{renderValuePrivate('previous', privateDocument.previous)}</td></tr>
<tr><td>Previous academic course percentage:</td><td>{renderValuePrivate('previousPercentage', privateDocument.previousPercentage)}</td></tr>
<tr><td>Total Fees:</td><td>{renderValuePrivate('totalFees', privateDocument.totalFees)}</td></tr>
<tr><td>Fees Paid:</td><td>{renderValuePrivate('feesPaid', privateDocument.feesPaid)}</td></tr>
<tr><td>Other Scholarship:</td><td>{renderValuePrivate('otherScholarship', privateDocument.otherScholarship)}</td></tr>
<tr><td>Entrance Rank:</td><td>{renderValuePrivate('entranceRank', privateDocument.entranceRank)}</td></tr>

        <tr><td>Why They Deserve:</td><td>{renderValue('explanation', document.explanation)}</td> </tr>
        <tr><td>Family Financial Details:</td><td>{renderValuePrivate('familyFinancialDetails', privateDocument.familyFinancialDetails)}</td></tr>
<tr><td>Reference 1:</td><td>{renderValuePrivate('reference1', privateDocument.reference1)}</td></tr>
<tr><td>Reference 2:</td><td>{renderValuePrivate('reference2', privateDocument.reference2)}</td></tr>

        {privateDocument.EWS_URL!="empty"&&<tr><td>EWS Certifcate: </td><td><a  target="_blank" href={privateDocument.EWS_URL}>EWS Certificate</a></td></tr>}
        {privateDocument.marksheetURL!="empty"&&<tr><td>Marksheet  (Year 1): </td><td><a target="_blank" href={privateDocument.marksheetURL}>Marksheet Year 1</a></td></tr>}
        {privateDocument.marksheetURL2!="empty"&&<tr><td>Marksheet (Year 2): </td><td><a target="_blank" href={privateDocument.marksheetURL2 }>Marksheet Year 2</a></td></tr>}

        {privateDocument.FIN_AID_URL!="empty" && <tr><td>Financial Aid Certificate</td><td><a  target="_blank" href={privateDocument.FIN_AID_URL}>Financial Aid Certificate</a></td></tr>}
        {privateDocument.Admission_URL!="empty" && <tr><td>Admission Certificate: </td><td><a href={privateDocument.Admission_URL}>Admission Certificate</a></td></tr>}
        
         <div style={{paddingTop:"1rem"}}>
         <Link to={`/studentlist/${id}/update`}><Button class="primary">Edit Status</Button></Link>
         </div>


         

       




        

        
        



        </tbody>

        </Table >

        <ButtonGroup>
         

         Update/Add the following files:
         {fileType && <Button value="cancel"  variant="danger" onClick={(e)=>handleFileUpdate(e.target.value)}>Cancel</Button>}

        <Button value="EWS" onClick={(e)=>handleFileUpdate(e.target.value)}>  EWS Certificate</Button>
         <Button value="Marksheet1" onClick={(e)=>handleFileUpdate(e.target.value)}> Marksheet Year 1 </Button>
         <Button value="Marksheet2" onClick={(e)=>handleFileUpdate(e.target.value)}>  Marksheet Year 2 </Button>
         <Button value="FinAid" onClick={(e)=>handleFileUpdate(e.target.value)}>Financial Aid Certificate</Button>
         <Button value="Admission" onClick={(e)=>handleFileUpdate(e.target.value)}>Admission Certificate</Button>
         </ButtonGroup>
         {fileType && <Form onSubmit={handleSubmit} style={{paddingTop:"2rem"}}>

            <Form.Group class="mb-3">
            <Form.Label>Upload new file for {fileType}</Form.Label>
            <Form.Control required type="file" onChange={(e)=>setFile(e.target.files[0])}></Form.Control>
            </Form.Group>
            <Button type="sumbit">Submit </Button>
         
            </Form>}   

        </div>
     )
 }
 else{
    return null
 }
 


}
import firebase from 'firebase/app'
import 'firebase/firestore' 
import 'firebase/auth'
import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyCUZxBKRM394eo1fCKYLeby3b-tuCIV0gs",
    authDomain: "hope-and-light-scholarships.firebaseapp.com",
    projectId: "hope-and-light-scholarships",
    storageBucket: "hope-and-light-scholarships.appspot.com",
    messagingSenderId: "82787889296",
    appId: "1:82787889296:web:0d3c58d82a6ade4b78a815"
  };

//init

firebase.initializeApp(firebaseConfig)



//init services

const projectFirestore=firebase.firestore()
const projectAuth=firebase.auth()
const projectStorage=firebase.storage()
const timestamp=firebase.firestore.Timestamp
export {projectFirestore,projectAuth,timestamp,projectStorage}
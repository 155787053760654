import { NavLink } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";

// styles & images
import DashboardIcon from "../assets/dashboard_icon.svg";
import CreateIcon from "../assets/add_icon.svg";

import { useDocument } from "../hooks/useDocument";

import "./Sidebar.css";

export default function Sidebar() {
  const { user } = useAuthContext();
  const { document, error } = useDocument("users", user.uid);

  return (
    <>
      {error && <p>{error}</p>}
      {document && document.type === "admin" && (
        <div className="sidebar">
          {document && document.type === "admin" && (
            <div className="sidebar-content">
              <nav className="links">
                <ul>
                  <li>
                    <NavLink exact to="/create">
                      <img src={CreateIcon} alt="dashboard icon" />
                      <span>Create a New Scholarship</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/studentlist">
                      <img src={DashboardIcon} alt="dashboard icon" />
                      <span>Student List</span>
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
      )}
      <style>
        {`
          
  
          /* Add responsive styles */
          @media (max-width: 600px) {
            .sidebar {
              width: 10px; /* Adjust the width as needed */
            }
  
            .sidebar-content span {
              display: none;
            }
          }
        `}
      </style>
    </>
  );
}

import React from 'react'
import { useCollection } from '../../hooks/useCollection'

import  { useState } from 'react';
import { Link } from 'react-router-dom';
import ProjectFilter from '../dashboard/ProjectFilter';
import { useAuthContext } from '../../hooks/useAuthContext';
import { Table, Form, Row, Col } from 'react-bootstrap';
import { Pagination } from 'react-bootstrap';
import List from '../../assets/lists.png'
import "./PublicList.css"
 
export default function PublicList() {
  const {documents,error}=useCollection("users")
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 15;
  const [searchQuery, setSearchQuery] = useState('');

  const changeSearchQuery = (event) => {
    setSearchQuery(event.target.value);
  };

  const searchedStudents = documents
    ? documents.filter((document) =>
        document.displayName.toLowerCase().includes(searchQuery.toLowerCase()) && document.dbAgree==="Yes" && document.status!=="Approved" && document.type==="user" 
      )
    : null;

    const lastRowIndex = currentPage * rowsPerPage;
    const firstRowIndex = lastRowIndex - rowsPerPage;
    const currentStudents = searchedStudents
      ? searchedStudents.slice(firstRowIndex, lastRowIndex)
      : null;

      
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalPages = Math.ceil(
    (searchedStudents?.length || 0) / rowsPerPage
  );


  return (
    <div>
       
    <div className="top-image-container">
        <img className="top-image" src={List} alt="Top Image" />
      </div>
    <div className="project-list"></div>

      <div style={{ paddingTop: '1rem',paddingBottom: '1rem'  }}>
              <Form.Control
                type="text"
                value={searchQuery}
                style={{ height: '3rem' }}
                onChange={changeSearchQuery}
                placeholder="Search by name"
              />
    </div>
      {error && <p>{error}</p>}
      
    <Table striped>
      <thead>
        <tr>
          <th>Name</th>
          <th>Scholarship Usage</th>
          <th>Reason</th>
        </tr>
      </thead>
      <tbody>
        {currentStudents && currentStudents.map(document=>(
            <>
            {document && 
            
            <tr>
                <td>{document.displayName}</td>
                <td>{document.scholarshipUsage}</td>
                <td>{document.explanation}</td>


            </tr>
            }
            </>
        ))}
      </tbody>
    </Table>
    {searchedStudents && searchedStudents.length > rowsPerPage && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination className="mt-3">
            {Array.from({ length: totalPages }).map((_, index) => (
              <Pagination.Item
                key={index}
                active={currentPage === index + 1}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
      )}
      {!documents && <p>Loading...</p>}
      {documents && documents.length === 0 && <p>No users yet!</p>}
      <div style={{ textAlign: 'center', marginTop: '1rem', paddingBottom:'1.2rem' }}>
        Current Page: {currentPage} | Total Pages: {totalPages}
      </div>

    </div>

  )
}
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useCollection } from '../../hooks/useCollection';
import ProjectFilter from '../dashboard/ProjectFilter';
import { useAuthContext } from '../../hooks/useAuthContext';
import { Table, Form } from 'react-bootstrap';
import { Pagination } from 'react-bootstrap';

import firebase from 'firebase/app';
import 'firebase/firestore';

export default function StudentList() {
  const { documents, error } = useCollection('users');
  const { user } = useAuthContext();
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const changeFilter = (newFilter) => {
    setFilter(newFilter);
  };

  const changeSearchQuery = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredStudents = documents
    ? documents.filter((document) => {
        switch (filter) {
          case 'All':
            return true;
          case 'Pending':
          case 'Approved':
          case 'Denied':
            return document.status === filter;
          default:
            return true;
        }
      })
    : null;
    
    const verified = async (document) => {
      try {
        const userDoc = await firebase.firestore().collection('users').doc(document.id).get();
        if (userDoc.exists) {
          const userDocData=userDoc.data().user()
          console.log(userDocData)

          return userDocData.emailVerified;
        }
      } catch (error) {
        console.log('Error fetching user object:', error);
      }
      return null;
    };

  const searchedStudents = filteredStudents
    ? filteredStudents.filter((document) =>
        document.displayName.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : null;

    const verifiedStudents = searchedStudents
    ? searchedStudents.filter((document) => {
         return verified(document)
      })
    : null;

  // Calculate pagination
  const lastRowIndex = currentPage * rowsPerPage;
  const firstRowIndex = lastRowIndex - rowsPerPage;
  const currentStudents = verifiedStudents
    ? verifiedStudents.slice(firstRowIndex, lastRowIndex)
    : null;

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  

  const totalPages = Math.ceil(
    (searchedStudents?.length || 0) / rowsPerPage
  );

  return (
    <div style={{ width: '40rem' }}>
      {error && <p>{error}</p>}
      {user && documents && (
        <div style={{ marginLeft: '-10rem' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '1rem',
            }}
          >
            <ProjectFilter changeFilter={changeFilter} />
            <div style={{ paddingTop: '3rem', marginLeft: '-2rem' }}>
              <Form.Control
                type="text"
                value={searchQuery}
                style={{ height: '3rem' }}
                onChange={changeSearchQuery}
                placeholder="Search by name"
              />
            </div>
          </div>
        </div>
      )}
      <Table striped>
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {currentStudents  &&
            currentStudents.map((document) => (
              <>
              {document && document.type==="user" &&  <tr key={document.id}>
                <td>
                  <Link to={`/studentlist/${document.id}`}>
                    {document.displayName}
                  </Link>
                </td>
                <td>{document.status}</td>
              </tr>}
              </>
              
            ))}
        </tbody>
      </Table>
      {searchedStudents && searchedStudents.length > rowsPerPage && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination className="mt-3">
            {Array.from({ length: totalPages }).map((_, index) => (
              <Pagination.Item
                key={index}
                active={currentPage === index + 1}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
      )}
      {!documents && <p>Loading...</p>}
      {documents && documents.length === 0 && <p>No users yet!</p>}
      <div style={{ textAlign: 'center', marginTop: '1rem' }}>
        Current Page: {currentPage} | Total Pages: {totalPages}
      </div>
    </div>
  );
}

import { useState } from 'react'
import { useAdminLogin } from '../../hooks/useAdminLogin'
// styles
import './Login.css'
import { useHistory } from 'react-router'
import { useLogout } from '../../hooks/useLogout'
import { useEffect } from 'react'
import { projectAuth, projectFirestore } from '../../firebase/config'
import { useAuthContext } from '../../hooks/useAuthContext'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
// styles
import './Login.css'


export default function AdminLogin() {

  const history = useHistory()
  const {user}=useAuthContext()
  const {logout,isPending2} = useLogout()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login, error, isPending,type } = useAdminLogin()
  // const {user}= useAuthContext()

  const handleSubmit = async (e) => {
    e.preventDefault()
    login(email, password)
}


  return (
    <div style={{padding:'15px', width:"50rem"}}>
       <Form onSubmit={handleSubmit} className="auth-form">
      <h2>Admin Login</h2>
        <Form.Label>Email:</Form.Label>
        <Form.Control
          required
          type="email" 
          onChange={(e) => setEmail(e.target.value)} 
          value={email} 
        />
    
        <Form.Label>Password:</Form.Label>
        <Form.Control 
          required
          type="password" 
          onChange={(e) => setPassword(e.target.value)} 
          value={password} 
        />
        <br></br>
      {/* {typeError && <p className='error'>Must be user.</p>} */}
      {!isPending && <Button className="primary" type="submit">Log in</Button>}
      {isPending && <Button className="primary" type="submit" disabled>loading</Button>}
      {error && <div className="error">{error}</div>}
    </Form>
    </div>
  )
}


import { useAuthContext } from '../../hooks/useAuthContext'
import ApporvedList from '../../components/ApprovedList'
// components
import {Link} from 'react-router-dom'

import { useState } from 'react'

// styles
import './Dashboard.css'
import Carousel from 'react-bootstrap/Carousel';
import Home from './Home'
import Education from "../../assets/education.jpg"
import Education2 from "../../assets/education2.jpg"
import Knowledge from "../../assets/knowledge.jpg"


export default function Dashboard() {
  const { user } = useAuthContext()
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  
  
  
  return (
    <div className='links-container' style={{paddingBottom:"100px"}}>
      {user  && <Home user={user}></Home>}
      {/* {error && <p className="error">{error}</p>} */}
      {!user&&(
        <div stye={{minWidth:"100rem",minHeight:"50rem"}}>
           <style>
            {`
            .responsive-carousel .carousel-item img {
              object-fit: cover;
              max-height: 500px; /* Adjust the max-height as needed */
              width: 100%;
            }

            @media (max-width: 768px) {
              .responsive-carousel .carousel-item img {
                max-height: 300px; /* Adjust the max-height for smaller screens */
              }
            }
          `}
          </style>
      <Carousel activeIndex={index} onSelect={handleSelect} className='responsive-carousel'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Education2}
          alt="First slide"
          height="600rem"
        />
        <Carousel.Caption>
          <h2><Link to='/preload' style={{color:"white"}}>Register for the H&L Scholarship Program</Link></h2>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Knowledge}
          alt="Second slide"
          height="600rem"

        />

        <Carousel.Caption>
          <h3>External Scholarships available to students</h3>
          <p>Government and Several NGOs are offering scholarships for meritorious students. Check it out! </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Education}
          alt="Third slide"
          height="600rem"

        />

        <Carousel.Caption>
          <h3><Link to="/publiclist" style={{color:"white"}}>Student List</Link></h3>
          <p>
          Fund the education of the students awaiting scholarships
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
      <br></br>
     <div style={{textAlign: "left"}}><p style={{fontSize:"1.3rem"}}>H&L had a great 2023 supporting meritorious girls with scholarships to higher education. We hope to improve this year on year.  We could NOT have done it without the support from our Donors. Thank You! A small list of beneficiaries below : 
</p></div> 
     <br></br>
      <ApporvedList></ApporvedList>
      
      </div>)}
    </div>
  )
}
import { useState } from 'react'
import { useLogin } from '../../hooks/useLogin'
import { useLogout } from '../../hooks/useLogout'
import { useEffect } from 'react'
import { projectAuth, projectFirestore } from '../../firebase/config'
import { useAuthContext } from '../../hooks/useAuthContext'
// styles
import './Login.css'
import { useHistory, Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

export default function Login() {
  const { logout, isPending2 } = useLogout()
  const { user } = useAuthContext()
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login, error, isPending, type } = useLogin()

  const handleSubmit = async (e) => {
    e.preventDefault()
    login(email, password)
  }

  return (
    <div>
      <Form onSubmit={handleSubmit} className="auth-form">
        <h2>Login</h2>
        <Form.Label>Email:</Form.Label>
        <Form.Control
          required
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />

        <Form.Label>Password:</Form.Label>
        <Form.Control
          required
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <br></br>
        {!isPending && (
          <>
          <Form.Group class="mb-2">
            <Button className="primary" type="submit">
              Log in
            </Button>
            </Form.Group>
            <Link to="/forgot-password" style={{fontSize:"14px"}}>
              Forgot Password
            </Link>
          </>
        )}
        {isPending && (
          <Button className="primary" type="submit" disabled>
            loading
          </Button>
        )}
        {error && <div className="error">{error}</div>}
      </Form>
    </div>
  )
}

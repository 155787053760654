import { Link } from 'react-router-dom'

import Scholarship from '../assets/final_scholarship.png'
// styles
import './ScholarshipList.css'
import Table from 'react-bootstrap/Table'


export default function ProjectList({ projects }) {
  console.log(projects)


  const addProtocolIfMissing = (url) => {
    if (url && !/^https?:\/\//i.test(url)) {
      return 'http://' + url;
    }
    return url;
  };
  return (
    <>
    
    <div className="top-image-container">
        <img className="top-image" src={Scholarship} alt="Top Image" />
      </div>
    <div style={{paddingTop:"1.2rem"}}>
    <Table striped >
      <thead>
        <tr>
          <th>Name </th>
          <th>Applicable For </th>
          <th>Website</th>
        </tr>
      </thead>
      <tbody>
      {projects.length === 0 && <p>No projects yet!</p>}
      {projects.map(project => (
        <tr>
          <td>{project.name}</td>
          <td>{project.applicable}</td>
          <td><a href={addProtocolIfMissing(project.website)} target="_blank" rel="noopener noreferrer">
                    {project.website}
                  </a></td>

        </tr>
       
        
      ))}
      </tbody>
    </Table>

    </div>
    </>
  )
}

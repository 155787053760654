import { useCollection } from '../hooks/useCollection'

import * as React from 'react';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import { useState } from 'react';

export default function ApporvedList(){
    const {documents,error}=useCollection('users')
    let approvedDocuments=null
    if( documents ){
       
        approvedDocuments = documents
        .filter((document) => document.type === 'user' && document.status === 'Approved')
        .sort((a, b) => new Date(b.dateOfApproval) - new Date(a.dateOfApproval))
        .slice(0, 5); // Limit to 5 approved documents, sorted by date of approval


    }
    
    const formatAsDDMMYYYY = (date) => {
      const dateObj = new Date(date);
      const day = String(dateObj.getDate()).padStart(2, '0');
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const year = dateObj.getFullYear();
      return `${day}-${month}-${year}`;
    };
   
 

  return (
    <div>
      <CardGroup >
        {error && <p>{error}</p>}
        {documents  && approvedDocuments &&  approvedDocuments.map(document=>(
            <>
            {document.type==="user" &&  document.status==="Approved" && 
            (<Card>
                <Card.Body>
          <Card.Title>Name: {document.displayName}</Card.Title>
          
          <Card.Text>
          Scholarship For: {document.scholarshipUsage}

          </Card.Text>

        </Card.Body>
            
            </Card>) 
            }
      

</>
))}
        
      
    </CardGroup>
    </div>
    
  );


}